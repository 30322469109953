import { Form } from 'antd';
import { Outlet } from 'react-router-dom';
import { SalesOrderFormNavigation } from './UserFormNavigation';

export const CreateUserForm = (): JSX.Element => {
  return (
    <>
      <SalesOrderFormNavigation />
      <Form style={{ marginBottom: 16 }} layout="vertical">
        <Outlet />
      </Form>
    </>
  );
};
