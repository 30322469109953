/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, List, Space, Switch, Typography } from 'antd';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { useFormikContext } from 'formik';
import { Business } from 'models/Business';
import { Users } from 'models/Users';
import { FC, LegacyRef, MouseEvent, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { setSelectedDivisions } from 'redux/slices/selectedDivisionSlice';
import { addUnassignDivision, removeUnassignDivision } from 'redux/slices/selectedRoles';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { InlineStylesModel } from '../../models/InlineStylesModel';
import { DivisionList } from './DivisionList';

const styles: InlineStylesModel = {
  wrapper: {
    backgroundColor: 'white',
    paddingLeft: 10,
    borderRadius: 5,
    boxShadow: '0 0 4px #8181811a'
  },
  container: {
    alignItems: 'center'
  },
  title: {
    fontWeight: '100',
    marginBottom: 0
  },
  altTitle: {
    fontWeight: '100',
    color: '#18a799',
    marginBottom: 0
  }
};

interface Props {
  business: Business;
  userData?: Users;
}

export const BusinessListItem: FC<Props> = ({ business, userData }) => {
  /* ******************** Hooks ******************** */
  const [showDivision, _setShowDivisions] = useState(false);
  const { customerSearch } = useAppSelector((state) => state.businessList);
  const { unassignDivisions } = useAppSelector((state) => state.selectedRoles);
  const { selectedDivisions } = useAppSelector((state) => state.selectedDivisions);
  const { setTouched, touched } = useFormikContext();

  const dispatch = useAppDispatch();

  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 },
    height: showDivision ? height : 0
  });

  const filteredSelectedDivisions = business.divisions.filter((division) => selectedDivisions.includes(division.id));

  /* ******************** Functions ******************** */

  const handleToggleShowDivision = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    _setShowDivisions((prevState) => !prevState);
  };

  useEffect(() => {
    if (customerSearch) {
      _setShowDivisions(true);
    }
    if (!business.divisions.length) _setShowDivisions(false);
  }, [business.divisions.length, customerSearch]);

  useEffect(() => {
    if (customerSearch === '') {
      _setShowDivisions(false);
    }
  }, [customerSearch]);

  const handleAddBusinessDivisions = (value: boolean): void => {
    const divisionIds = business.divisions.map((division) => division.id);
    if (value === false) {
      const filteredDivisions = selectedDivisions.filter((division) => !divisionIds.includes(division));
      divisionIds.map((divisionId) => (!unassignDivisions.includes(divisionId) ? dispatch(addUnassignDivision(divisionId)) : undefined));
      dispatch(setSelectedDivisions(filteredDivisions));
      _setShowDivisions(true);
    } else {
      _setShowDivisions(false);
      divisionIds.map((divisionId) => dispatch(removeUnassignDivision(divisionId)));
      dispatch(setSelectedDivisions([...selectedDivisions, ...divisionIds]));
    }
  };

  useEffect(() => {
    return (): void => setTouched({ ...touched, divisionIdsToAssign: true });
  }, []);

  return (
    <>
      <List.Item onClick={business.divisions.length ? handleToggleShowDivision : undefined} style={business.divisions.length ? { ...styles.wrapper, ...{ cursor: 'pointer' } } : styles.wrapper}>
        <List.Item.Meta
          avatar={<Avatar src={business.logoUrl} />}
          title={
            <Space>
              <Typography.Title level={5} style={styles.title}>
                {business.name}
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.title}>
                |
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                {business.erpId}
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.title}>
                |
              </Typography.Title>
              <Typography.Title level={5} type="secondary" style={styles.altTitle}>
                {`${business.divisions.length} Divisions`}
              </Typography.Title>
            </Space>
          }
          style={styles.container}
        />
        <div style={{ marginRight: 10 }}>{`${filteredSelectedDivisions.length} of ${business.divisions.length}`}</div>
        <Switch checked={!!filteredSelectedDivisions.length} onClick={(value) => handleAddBusinessDivisions(value)} />
        <Button disabled={!business.divisions.length} style={{ width: 115 }} type="text" onClick={handleToggleShowDivision} key="show-divisions" icon={<CaretSpinIcon isOpen={showDivision} />}>
          {showDivision ? 'Collapse' : 'Expand'}
        </Button>
      </List.Item>
      <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden' }, ...expandAnimation }}>
        {showDivision && (
          <div ref={ref as LegacyRef<HTMLDivElement>}>
            <DivisionList divisions={business.divisions} />
          </div>
        )}
      </animated.div>
    </>
  );
};
