import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import RecyclingBinCard from 'components/atoms/RecyclingBinCard';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUsersQuery } from 'redux/services/cookieMonster/cookieMonsterApi';
import { setReturnDeletedUsersOnly } from 'redux/services/cookieMonster/userParams';
import { ReduxState } from 'redux/store';

export const RecyclingBinList = (): JSX.Element => {
  const { userParams } = useSelector((state: ReduxState) => state);

  const { data: userData, isLoading, isFetching } = useGetUsersQuery({ ...userParams, returnDeletedUsersOnly: true });
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setReturnDeletedUsersOnly(undefined));
    };
  }, [dispatch]);

  /* ******************** Render ******************** */
  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      loading={{
        indicator: <BlockLoader direction="loader loader--slideUp" />,
        size: 'large',
        spinning: isLoading || isFetching
      }}
      style={{ margin: 0 }}
      dataSource={userData?.data}
      renderItem={(item): JSX.Element => <RecyclingBinCard user={item} />}
    />
  );
};
