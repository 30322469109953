import { Button, message, Modal, Row, Space, Spin, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { Users } from 'models/Users';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteUserMutation, useGetUsersQuery, useRecoverUserMutation } from 'redux/services/cookieMonster/cookieMonsterApi';
import { setSelectedUsers } from 'redux/slices/selectedApplicationSlice';

import { ReduxState } from 'redux/store';

interface Props {
  deletedUsers?: Users[] | undefined;
}

const RecoverActions = ({ deletedUsers }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [showPurgeModal, _setShowPurgeModal] = useState(false);
  const [applicationCount, _setApplicationCount] = useState(1);
  const { selectedUsers } = useSelector((state: ReduxState) => state.selectedUsers);
  const { userParams } = useSelector((state: ReduxState) => state);
  const { data: userData, isLoading, isFetching } = useGetUsersQuery({ ...userParams, returnDeletedUsersOnly: true });

  const [recoverUser, { isLoading: isRecoveringUser }] = useRecoverUserMutation();
  const [purgeUser, { isLoading: isDeletingUser }] = useDeleteUserMutation();

  const totalSelectedUsers = selectedUsers.length;

  const handleRestoreUsers = async (): Promise<void> => {
    if (selectedUsers.length < 1) {
      message.error('No entity selected!');

      return;
    }

    for (const user of selectedUsers) {
      try {
        await recoverUser(user.user_id).unwrap();
      } catch {
        message.error(`User ${user.name} failed to be recovered!`);
      }
    }

    dispatch(setSelectedUsers([]));
    message.success(`User(s) were successfully recovered!`);
  };

  const handlePurgeItems = async (): Promise<void> => {
    if (selectedUsers.length < 1) {
      message.error('No items have been selected to be deleted!');

      return;
    }
    for (const user of selectedUsers) {
      try {
        const test = await purgeUser({ userId: user.user_id, params: { wouldYouLikeToPlayAGame: true } });

        if ((test as any).error) {
          throw new Error((test as any).error);
        }

        _setApplicationCount((prev) => prev + 1);
        message.success(`Purged User(s) successfully!`);
      } catch (error) {
        message.error(`Failed to delete User ${user.user_id}`);
      }
    }

    _setShowPurgeModal(false);
    _setApplicationCount(1);
    dispatch(setSelectedUsers([]));
  };

  const handleSelectAll = (): void => {
    if (userData) {
      dispatch(setSelectedUsers(userData.data));
    }
  };

  const handleClearAll = (): void => {
    dispatch(setSelectedUsers([]));
  };

  return (
    <Row justify="end">
      <Row justify="space-between">
        <Button style={{ marginRight: 5 }} onClick={() => naviagte('/')}>
          Back
        </Button>
      </Row>
      <Row justify="space-between">
        <Button disabled={deletedUsers?.length === 0} onClick={!totalSelectedUsers ? handleSelectAll : handleClearAll} style={{ marginRight: 5 }} type="primary">
          {!totalSelectedUsers ? 'Select All' : 'Clear All'}
        </Button>
      </Row>
      <Row justify="space-between">
        <Button
          onClick={handleRestoreUsers}
          style={totalSelectedUsers ? { marginRight: 5, background: '#4e937a', borderColor: '#4e937a', color: 'white' } : { marginRight: 5 }}
          loading={isRecoveringUser}
          disabled={!totalSelectedUsers}>
          Restore Selected ({totalSelectedUsers})
        </Button>
        <Button onClick={(): void => _setShowPurgeModal(!showPurgeModal)} danger loading={isDeletingUser}>
          Purge Selected ({totalSelectedUsers})
        </Button>
      </Row>
      <Modal
        title="Are you sure?"
        okText={`Purge Items (${totalSelectedUsers})`}
        visible={showPurgeModal}
        onOk={handlePurgeItems}
        closable={!isDeletingUser}
        maskClosable={!isDeletingUser}
        onCancel={(): void => _setShowPurgeModal(false)}
        cancelButtonProps={isDeletingUser ? { style: { pointerEvents: 'none', opacity: '.3' } } : undefined}
        okButtonProps={isDeletingUser ? { style: { background: '#dc3545', pointerEvents: 'none', opacity: '.3', border: 'none' } } : { style: { background: '#dc3545', border: 'none' } }}>
        <Spin
          spinning={isDeletingUser}
          indicator={
            <Space style={{ width: '100%', marginLeft: '-50%', marginTop: '-37%' }}>
              <BlockLoader direction="loader loader--slideUp" message={`Purging User ${applicationCount} of ${totalSelectedUsers}...`} />
            </Space>
          }>
          <Typography.Text type="danger">IF YOU CONTINUE, YOU WILL NO LONGER BE ABLE TO RECOVER THE SELECTED USERS.</Typography.Text>
        </Spin>
      </Modal>
    </Row>
  );
};

export default RecoverActions;
