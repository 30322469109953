import { Modal } from 'antd';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { EditUserPayload } from 'models/Users';
import { FC } from 'react';
import { useAppSelector } from 'redux/store';
import { AssignPrinterForm } from './AssignPrinterForm';

type Props = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  setVisibility: (visible: boolean) => void;
  warehouse: any;
};

export const PrinterModal: FC<Props> = ({ visible, onCancel, setVisibility }) => {
  const { selectedWarehouse } = useAppSelector((state) => state.app);
  const {
    setFieldValue,
    values: { assignedPrinters }
  } = useFormikContext<EditUserPayload>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dataAreaId: '',
      name: ''
    },
    onSubmit: (values) => {
      setFieldValue('assignedPrinters', [...(assignedPrinters ?? []), values]);
      setVisibility(false);
      formik.resetForm();
    }
  });
  return (
    <Modal
      destroyOnClose
      title="Assign Printer"
      visible={visible}
      onCancel={() => {
        setVisibility(false);
        formik.resetForm();
      }}
      onOk={() => {
        formik.submitForm();
      }}>
      <FormikProvider value={formik}>
        <AssignPrinterForm />
      </FormikProvider>
    </Modal>
  );
};
