import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Collapse, List, Row, Switch, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { PermissionModel, RoleModel, Users } from 'models/Users';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addRole, addUnassignRole, removeRoles, removeUnassignRoles } from 'redux/slices/selectedRoles';
import { ReduxState } from 'redux/store';

const { Panel } = Collapse;

interface Props {
  roles: RoleModel;
  userData?: Users;
}

export const RolesCard: React.FC<Props> = ({ roles }) => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const { setTouched, touched } = useFormikContext();

  const { selectedRoles, unassignRoles } = useSelector((state: ReduxState) => state.selectedRoles);

  /* ******************** Variables / Functions ******************** */

  const handleToggle = (e: Event) => {
    e.stopPropagation();
    if (selectedRoles.includes(roles.id)) {
      dispatch(removeRoles(roles.id));

      if (!unassignRoles.includes(roles.id)) dispatch(addUnassignRole(roles.id));
    } else {
      dispatch(addRole(roles.id));

      if (unassignRoles.includes(roles.id)) dispatch(removeUnassignRoles(roles.id));
    }
  };
  interface PermissionsProps {
    item: PermissionModel;
  }

  const Permissions: React.FC<PermissionsProps> = ({ item }) => {
    return (
      <>
        <Typography.Title level={5} style={{ textTransform: 'capitalize', marginBottom: 0, color: '#2786fa' }}>
          {item.permission_name}
        </Typography.Title>
        <Typography.Paragraph>{item.description}</Typography.Paragraph>
      </>
    );
  };

  useEffect(() => {
    return (): void => setTouched({ ...touched, roleIdsToAssign: true });
  }, []);

  const modifiedName = roles.name.replace(/\w\S*/g, (txt: any) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

  return (
    <Row justify="space-between" align="middle">
      <Col style={{ marginTop: 16 }} span={24}>
        <Collapse>
          <Panel
            // collapsible="header"
            key={1}
            header={modifiedName}
            extra={
              <Switch
                checked={selectedRoles.includes(roles.id)}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onClick={(checked: boolean, e: Event): void => handleToggle(e)}
              />
            }>
            <List dataSource={roles.permissions} renderItem={(item: PermissionModel) => <Permissions item={item} />} />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  );
};
