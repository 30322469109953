import { Card, Col, List, Row, Switch, Typography } from 'antd';
import { toRgb, userFormColors } from 'common/styles/colors';
import { DivisionListItem } from 'components/atoms/DivisionListItem';
import { useFormikContext } from 'formik';
import { Division } from 'models/Division';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React, { useEffect, useState } from 'react';
import { useLazyGetDivisionQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { useGetRolesQuery } from 'redux/services/cookieMonster/cookieMonsterApi';

const styles: InlineStylesModel = {
  mainContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: 0,
    width: '100%'
    // height: 'calc(100vh - 112px - 46px - 50px - 110px - 25px )'
  },
  cardsContainer: {
    padding: '0px',
    margin: '0px'
  },

  infoTitle: {
    fontSize: '16px',
    fontWeight: 600
  },
  infoText: {
    fontSize: '15px',
    minHeight: '27px'
  },
  cardHead: {
    fontSize: '18px',
    fontWeight: 600
  },
  cardBody: {
    padding: '25px 20px 20px 25px'
  },
  headerCard: {
    backgroundColor: '#FFEAC1'
  },
  deliveryCard: {
    backgroundColor: '#CED5F2'
  },
  productsTableCard: {
    backgroundColor: '#b0b2bc'
  },
  tableBody: {
    padding: 0
  },
  extra: {
    fontSize: '15px',
    fontWeight: 500
  },
  addressLine: {
    marginBottom: 0
  },
  attention: {
    maxWidth: '300px'
  },
  red: {
    color: toRgb(userFormColors.redSalsa)
  },
  wrapper: {
    margin: 5
  },
  container: {
    padding: 15
  },
  title: {
    fontSize: '0.85em',
    // whiteSpace: 'nowrap',
    // ParagraphOverflow: 'ellipsis',
    color: 'black',
    textTransform: 'capitalize',
    marginBottom: 0
  },
  titleContainer: {
    paddingLeft: 10
  },
  iconContainer: {
    paddingLeft: 10
  },
  editButton: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 7
  },
  subTitle: {
    fontSize: '0.75em',
    marginBottom: 0
  }
};

export const OverviewStep = () => {
  const {
    values: { isMdsiEmployee, email, givenName, familyName, divisionIdsToAssign, roleIdsToAssign, employeeERPId, phoneNumber }
  } = useFormikContext();

  const { data: roles } = useGetRolesQuery({ includePermissions: false });
  const [fetchDivision] = useLazyGetDivisionQuery();
  const [divisions, setDivisions] = useState<Division[]>([]);

  const assignedRoles = roles?.data.filter((role) => roleIdsToAssign.includes(role.id));

  const getLazyDivision = async (divisionId: string) => {
    const data = await fetchDivision(divisionId);
    setDivisions((prev: any) => [...prev, data.data]);
  };

  useEffect(() => {
    if (divisionIdsToAssign.length && !divisions.length) {
      for (const divisionId of divisionIdsToAssign) {
        getLazyDivision(divisionId);
      }
    }
    return (): void => {
      setDivisions([]);
    };
  }, []);

  const headerInfoCardJSX = (
    <Card style={{ height: '100%', width: '100%' }} size="small" title="User Details" bordered={false} headStyle={{ ...styles.cardHead, ...styles.headerCard }} bodyStyle={styles.cardBody}>
      <Row gutter={16}>
        <Col span={8}>
          <Typography.Title level={5} style={styles.infoTitle}>
            First Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{givenName || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Last Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{familyName || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Phone Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{phoneNumber || 'N/A'}</Typography.Paragraph>
        </Col>
        <Col span={10}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Email:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{email || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            MDSi Employee
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>
            <Switch disabled defaultChecked={isMdsiEmployee} />
          </Typography.Paragraph>
          {employeeERPId && (
            <>
              <Typography.Title level={5} style={styles.infoTitle}>
                Employee ERP Id
              </Typography.Title>
              <Typography.Paragraph style={styles.infoText}>{employeeERPId || 'N/A'}</Typography.Paragraph>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );

  const assignedRolesInfo = (
    <Card style={{ height: '100%', width: '100%' }} size="small" title="Assigned Roles" bordered={false} headStyle={{ ...styles.cardHead, ...styles.deliveryCard }} bodyStyle={styles.cardBody}>
      <Row gutter={[8, 8]}>
        {assignedRoles?.map((role) => (
          <Col span={12}>
            <Card style={styles.wrapper} bodyStyle={styles.container}>
              <Typography.Paragraph style={styles.title}>{role.name}</Typography.Paragraph>
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );

  /* ****************** Render ****************** */
  return (
    <Row style={styles.mainContainer} gutter={[0, 16]}>
      <Col span={24} style={styles.cardsContainer}>
        <Row gutter={[16, 16]}>
          <Col lg={24} xl={12} style={{ width: '100%' }}>
            {headerInfoCardJSX}
          </Col>
          <Col lg={24} xl={12} style={{ width: '100%' }}>
            {/* {assignedRolesInfo} */}
            <Card style={{ height: '100%', width: '100%' }} size="small" title="Assigned Roles" bordered={false} headStyle={{ ...styles.cardHead, ...styles.deliveryCard }} bodyStyle={styles.cardBody}>
              <List
                grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
                dataSource={assignedRoles}
                renderItem={(role) => (
                  <Card style={styles.wrapper} bodyStyle={styles.container}>
                    <Typography.Paragraph style={styles.title}>{role.name}</Typography.Paragraph>
                  </Card>
                )}
              />
            </Card>
          </Col>
        </Row>
      </Col>
      <Col span={24} style={styles.cardsContainer}>
        <Card title="Assigned Divisions" headStyle={{ ...styles.cardHead, ...styles.productsTableCard }}>
          <List
            grid={{ gutter: [8, 8] as any, xs: 1, sm: 2, md: 4, lg: 4, xl: 4, xxl: 4 }}
            dataSource={divisions}
            renderItem={(division): JSX.Element => <DivisionListItem division={division} />}
            rowKey={(item): string => item.id}
            style={{ padding: 8 }}
          />
        </Card>
      </Col>
    </Row>
  );
};
