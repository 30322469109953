import { message } from 'antd';
import React, { useEffect } from 'react';
import { setEmailContains } from 'redux/services/cookieMonster/userParams';
import { useAppDispatch } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../Inputs';

export const EmailContainsFilter = (): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useAppDispatch();

  /* ******************** Functions ******************** */
  const handleChange = useDebouncedCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value.length > 0 && value.length < 3) {
      message.error('Please enter more than 3 characters');
      return;
    }

    dispatch(setEmailContains(value || undefined));
  }, 500);

  // Clears state on unmount
  useEffect(() => {
    return (): void => {
      dispatch(setEmailContains(undefined));
    };
  }, [dispatch]);

  return <FancyInput placeholder="Email" onChange={handleChange} />;
};
