import { Space } from 'antd';
import { HomeControls } from 'components/organisms/HomeControls';
import { MemberList } from 'components/organisms/MemberList';

export const HomePage = (): JSX.Element => {
  return (
    <Space size={16} direction="vertical">
      <HomeControls />
      <MemberList />
    </Space>
  );
};
