import { Card, Col, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';
import { EmployeeSelectInput } from 'components/UI/FormItems/EmployeeSelectInput';
import { FormRadio } from 'components/UI/FormItems/Radio';
import { useFormikContext } from 'formik';
import { CreateUserPayload } from 'models/Users';
import { useGetEmployeesQuery } from 'redux/services/leftyTheSalesman/leftyTheSalesmanApi';

const UserDetailsStep = () => {
  const { values } = useFormikContext();
  const { data: mdsiEmployees, isLoading: isLoadingEmployees } = useGetEmployeesQuery();
  const employeeOptions = mdsiEmployees?.data.map((employee) => ({ label: `${employee.name} - ${employee.personnelNumber}`, value: `${employee.name} - ${employee.personnelNumber}` }));
  return (
    <Card>
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <TextInput fieldName="givenName" label="First Name" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="familyName" label="Last Name" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="phoneNumber" label="Phone Number" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="email" label="Email" />
        </Col>
        <Col span={24}>
          <FormRadio fieldName="isMdsiEmployee" label="MDSi Employee?" defaultValue={false} />
        </Col>
        {(values as CreateUserPayload).isMdsiEmployee && (
          <Col span={24}>
            <EmployeeSelectInput defaultValue="" showSearch loader={isLoadingEmployees} options={employeeOptions} fieldName="employeeERPId" label="Employee ERP ID" />
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default UserDetailsStep;
