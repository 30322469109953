import { AppstoreOutlined, MailTwoTone, PhoneTwoTone } from '@ant-design/icons';
import { Avatar, Card, List, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { InlineStylesModel } from '../../models/InlineStylesModel';

const styles: InlineStylesModel = {
  card: {
    borderRadius: '4px',
    cursor: 'pointer',
    textAlign: 'center'
  },
  cardLink: {
    display: 'flex',
    justifyContent: 'center'
  },
  icons: {
    fontSize: 35,
    color: 'rgb(89, 93, 110)',
    marginBottom: 20,
    borderRadius: 45
  },
  meta: {
    fontSize: '1em',
    width: '100%',
    marginBottom: 5,
    color: 'rgb(116, 120, 141)'
  }
};

export const MemberCard = ({ user }: any): JSX.Element => {
  /* ******************** Hooks ******************** */

  const navigate = useNavigate();

  /* ******************** Functions ******************** */

  const handleCardClick = () => {
    navigate(`users/${user.user_id}`);
  };

  /* ******************** Render ******************** */
  return (
    <List.Item>
      <Card hoverable style={styles.card} onClick={handleCardClick}>
        <Avatar icon={<AppstoreOutlined style={styles.icons} />} style={{ marginBottom: 16 }} size={75} alt="" src={user ? user.user_metadata.profile_image_url : undefined} />
        <Typography.Title level={4} ellipsis={{ rows: 1, tooltip: user.name }} style={{ whiteSpace: 'normal', marginBottom: 0 }}>
          {user.name}
        </Typography.Title>
        <Typography.Paragraph style={{ marginBottom: 0, color: 'rgb(116, 120, 141)' }} ellipsis={{ rows: 1, tooltip: user.email }}>
          <MailTwoTone twoToneColor={'green'} style={{ marginRight: 5 }} />
          {user.email ? user.email : 'No Description Available'}
        </Typography.Paragraph>
        <Typography.Paragraph style={{ marginBottom: 0, color: 'rgb(116, 120, 141)' }} ellipsis={{ rows: 1, tooltip: user.email }}>
          <PhoneTwoTone style={{ marginRight: 5 }} /> {user.user_metadata.phone_number ? user.user_metadata.phone_number : 'N/A'}
        </Typography.Paragraph>
      </Card>
    </List.Item>
  );
};
