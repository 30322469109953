import { DeleteOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useFormikContext } from 'formik';
import { AuthorizedWarehousesModel, EditUserPayload } from 'models/Users';

export const WarehouseTable = (): JSX.Element => {
  const {
    values: { authorizedWarehouses },
    setFieldValue
  } = useFormikContext<EditUserPayload>();
  const columns: ColumnsType<AuthorizedWarehousesModel> = [
    {
      title: 'Warehouse Code',
      dataIndex: 'warehouseCode',
      key: 'warehouseCode'
    },
    {
      title: 'Data Area ID',
      dataIndex: 'dataAreaId',
      key: 'dataAreaId'
    },
    {
      title: 'Is Default',
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (isDefault: boolean) => (isDefault ? 'Yes' : 'No')
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <Button
            icon={<DeleteOutlined />}
            danger
            size="small"
            onClick={() => {
              setFieldValue(
                'authorizedWarehouses',
                authorizedWarehouses?.filter((warehouse) => warehouse.warehouseAddressId !== record.warehouseAddressId)
              );
            }}
          />
        );
      }
    }
  ];
  return <Table columns={columns} rowKey={(record) => record.warehouseAddressId} dataSource={authorizedWarehouses} />;
};
