import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const Auth0ProviderWithHistory = (props: Omit<Auth0ProviderOptions, 'domain' | 'clientId' | 'redirectUri' | 'audience' | 'onRedirectCallback'>): JSX.Element => {
  const domain = process.env.REACT_APP_AUTH_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const navigate = useNavigate();

  const onRedirectCallback = (appState: AppState): void => {
    if (appState.targetUrl === '/profile') navigate('/profile');
    else navigate(appState.returnTo || window.location.pathname);
  };

  return <Auth0Provider {...props} domain={domain} clientId={clientId} redirectUri={window.location.origin} audience={process.env.REACT_APP_AUDIENCE} onRedirectCallback={onRedirectCallback} />;
};
