import { BarsOutlined, CarOutlined, CheckOutlined, ReconciliationOutlined, RightOutlined, UserAddOutlined } from '@ant-design/icons';
import { Menu, Space } from 'antd';
import { SalesOrderFormMenuKeys } from 'common/enums/MenuKeys';
import { toRgb, userFormColors } from 'common/styles/colors';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { CreateUserPayload } from 'models/Users';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const styles: InlineStylesModel = {
  valid: {
    color: toRgb(userFormColors.illuminatingEmerald)
  }
};

export const SalesOrderFormNavigation: React.FC = () => {
  /* ******************** Hooks ******************** */
  const { id } = useParams();
  const { values } = useFormikContext<CreateUserPayload>();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  /* ******************** Variables / Functions ******************** */

  const validUserDetailsStep = Boolean(values.givenName && values.familyName && values.email);

  const validAssignRolesStep = Boolean(values.roleIdsToAssign.length);
  const validDeliveryAddressStep = Boolean(values.divisionIdsToAssign.length);

  const isDraft = pathname.includes('/drafts/');
  const isCreate = pathname.includes('/create');
  const isPosted = pathname.includes('/posted');

  const currentLocation = useMemo(() => {
    if (pathname.includes(SalesOrderFormMenuKeys.UserDetails)) {
      return SalesOrderFormMenuKeys.UserDetails;
    } else if (pathname.includes(SalesOrderFormMenuKeys.AssignRoles)) {
      return SalesOrderFormMenuKeys.AssignRoles;
    } else if (pathname.includes(SalesOrderFormMenuKeys.AssignDivisions)) {
      return SalesOrderFormMenuKeys.AssignDivisions;
    } else if (pathname.includes(SalesOrderFormMenuKeys.Overview)) {
      return SalesOrderFormMenuKeys.Overview;
    }
  }, [pathname]);

  const handleNavigation = (info: { key: string }): void => {
    if (isCreate) {
      if (info.key === SalesOrderFormMenuKeys.UserDetails) {
        navigate('/create/user-details');
      } else if (info.key === SalesOrderFormMenuKeys.AssignRoles) {
        navigate('/create/assign-roles');
      } else if (info.key === 'assign-divisions') {
        navigate(`/create/assign-divisions`);
      } else {
        navigate(`/create/overview`);
      }
    } else if (isDraft) {
      if (info.key === SalesOrderFormMenuKeys.AssignDivisions) {
        navigate(`/drafts/${id}`);
      } else {
        navigate(`/drafts/${id}/${info.key}`);
      }
    } else if (isPosted) {
      if (info.key === SalesOrderFormMenuKeys.Overview) {
        navigate(`/posted/${id}`);
      } else {
        navigate(`/posted/${id}/${info.key}`);
      }
    }
  };

  /* ******************** Renderer ******************** */
  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Menu disabledOverflow={true} defaultSelectedKeys={[SalesOrderFormMenuKeys.UserDetails]} onClick={handleNavigation} mode="horizontal" style={{ justifyContent: 'center' }}>
        <Menu.Item key={SalesOrderFormMenuKeys.UserDetails}>
          <Space style={validUserDetailsStep ? styles.valid : undefined}>
            <UserAddOutlined />
            <div>User Details</div>
            {validUserDetailsStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item key={SalesOrderFormMenuKeys.AssignRoles}>
          <Space style={validAssignRolesStep ? styles.valid : undefined}>
            <BarsOutlined />
            <div>Assign Roles</div>
            {validAssignRolesStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item key={SalesOrderFormMenuKeys.AssignDivisions}>
          <Space style={validDeliveryAddressStep ? styles.valid : undefined}>
            <CarOutlined />
            <div>Assign Divisions</div>
            {validDeliveryAddressStep && <CheckOutlined />}
            <RightOutlined />
          </Space>
        </Menu.Item>
        <Menu.Item key={SalesOrderFormMenuKeys.Overview}>
          <Space>
            <ReconciliationOutlined />
            <div>Overview</div>
            <RightOutlined />
          </Space>
        </Menu.Item>
      </Menu>
    </Space>
  );
};
