import { Form, Select, SelectProps } from 'antd';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { addRole } from 'redux/slices/selectedRoles';
import { ReduxState } from 'redux/store';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps {
  fieldName: string;
  label?: string;
  rolesArray?: string[];
}

export const SelectInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);
  const { selectedRoles } = useSelector((state: ReduxState) => state.selectedRoles);
  const dispatch = useDispatch();

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select {...rest} onSelect={(value): any => dispatch(addRole(value.toString()))} onFocus={(): void => setTouched(false)} onBlur={(): void => setTouched(true)} style={{ borderRadius: 5 }} />
    </Form.Item>
  );
};
