import { User } from '@auth0/auth0-spa-js';
import { Modal } from 'antd';
import { FormikProvider, useFormik, useFormikContext } from 'formik';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedWarehouse } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import { AssignWarehouseForm } from './AssignWarehouseForm';

type Props = {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  setVisibility: (visible: boolean) => void;
  warehouse: any;
};

export const WarehouseModal: FC<Props> = ({ visible, onCancel, setVisibility }) => {
  const { selectedWarehouse } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const {
    setFieldValue,
    values: { authorizedWarehouses }
  } = useFormikContext<User>();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      warehouseCode: '',
      dataAreaId: '',
      warehouseAddressId: '',
      isDefault: false
    },
    onSubmit: (values) => {
      const payload = {
        warehouseCode: selectedWarehouse?.name,
        dataAreaId: selectedWarehouse?.business.dataAreaId,
        warehouseAddressId: selectedWarehouse?.id,
        isDefault: values.isDefault
      };
      setFieldValue('authorizedWarehouses', [...authorizedWarehouses, payload]);
      setVisibility(false);
      dispatch(setSelectedWarehouse(undefined));
      formik.resetForm();
    }
  });
  return (
    <Modal
      title="Assign Warehouse"
      destroyOnClose
      visible={visible}
      onCancel={() => {
        setVisibility(false);
        formik.resetForm();
        dispatch(setSelectedWarehouse(undefined));
      }}
      onOk={() => {
        formik.submitForm();
      }}>
      <FormikProvider value={formik}>
        <AssignWarehouseForm />
      </FormikProvider>
    </Modal>
  );
};
