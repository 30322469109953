import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, List, Row, Tooltip, Typography } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { Users } from 'models/Users';
import { useDispatch, useSelector } from 'react-redux';
import { addSelectedUsers, removeSelectedUsers } from 'redux/slices/selectedApplicationSlice';

import { ReduxState } from 'redux/store';

const styles: InlineStylesModel = {
  wrapper: {
    margin: 0,
    height: '100%'
  },
  card: {
    borderRadius: 5,
    textAlign: 'center'
  },
  selectedCard: {
    // border: '2px solid #5867dd',
    borderRadius: 5,
    textAlign: 'center'
  },
  cardBody: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15
  },
  largeText: {
    fontSize: '22px',
    fontFamily: 'Poppins',
    fontWeight: 700,
    color: '#000'
  },
  title: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#636770'
  },
  subTitle: {
    fontSize: '15px',
    color: '#A0A3AE',
    minHeight: '27px'
  },
  text: {
    fontSize: '13px',
    color: '#A0A3AE'
  },
  smallText: {
    fontSize: '11px',
    color: 'lightGrey'
  },
  status: {
    fontSize: '16px',
    fontWeight: 700
  },
  draft: {
    color: 'orange'
  },
  posted: {
    color: '#4E937A'
  },
  soNumber: {
    fontSize: '14px',
    color: '#5868DD'
  },
  poNumber: {
    fontSize: '14px',
    color: 'gray'
  },
  customerId: {
    fontSize: '16px',
    color: '#636770'
  },

  cardLink: {
    display: 'flex',
    justifyContent: 'center'
  },
  icons: {
    fontSize: 60,
    color: 'rgb(89, 93, 110)',
    marginBottom: 20,
    width: 60,
    borderRadius: 45
  },
  meta: {
    fontSize: '1em',
    width: '100%',
    marginBottom: 5,
    color: 'rgb(116, 120, 141)'
  }
};

interface Props {
  user: Users;
}

const RecyclingBinCard = ({ user }: Props): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedUsers } = useSelector((state: ReduxState) => state.selectedUsers);

  const isSelected = selectedUsers.some((item: any) => item.user_id === user.user_id);

  const handleToggleSelectProduct = (): void => {
    if (isSelected) {
      dispatch(removeSelectedUsers(user));
    } else {
      dispatch(addSelectedUsers(user));
    }
  };

  const readOnlyCardJSX = (
    <>
      <Row justify="space-between" align="middle" style={{ border: 'none', margin: 0 }}>
        <Typography.Text style={styles.largeText}>
          <Tooltip title="Application Name" placement="right" autoAdjustOverflow={false} overlayInnerStyle={{ fontSize: 12, textAlign: 'center' }}>
            {user.name}
          </Tooltip>
        </Typography.Text>
        <Button style={{ borderRadius: '50%' }} onClick={handleToggleSelectProduct} type={isSelected ? 'primary' : 'default'} icon={isSelected ? <CheckOutlined /> : ' '} />
      </Row>
      <Card bordered={false} style={{ margin: 0 }}>
        <Meta
          style={styles.meta}
          description={
            <Typography.Paragraph style={{ marginBottom: 0, color: 'rgb(116, 120, 141)' }} ellipsis={{ rows: 1, tooltip: user.email }}>
              {user.email ? user.email : 'No Description Available'}
            </Typography.Paragraph>
          }
        />
      </Card>
    </>
  );

  return (
    <List.Item style={styles.wrapper} colStyle={{ height: '100%', marginBottom: 8, padding: 4 }}>
      <Card hoverable onClick={handleToggleSelectProduct} style={isSelected ? styles.selectedCard : styles.card} bodyStyle={styles.cardBody}>
        {readOnlyCardJSX}
      </Card>
    </List.Item>
  );
};

export default RecyclingBinCard;
