import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Card, Col, List, Row, Switch, Typography } from 'antd';
import { Business } from 'models/Business';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { addSelectedDivisions, removeSelectedDivisions } from 'redux/slices/selectedDivisionSlice';
import { addUnassignDivision, removeUnassignDivision } from 'redux/slices/selectedRoles';
import { ReduxState } from 'redux/store';

const { Paragraph } = Typography;

interface Props {
  division: Business['divisions'][number];
}
const styles: InlineStylesModel = {
  wrapper: {
    height: '100%'
  },
  container: {
    padding: 15
  },
  title: {
    fontSize: '0.85em',
    color: 'green',
    marginBottom: 0
  },
  titleContainer: {
    paddingLeft: 10
  },
  iconContainer: {
    paddingLeft: 10
  },
  editButton: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 7
  },
  subTitle: {
    fontSize: '0.75em',
    marginBottom: 0
  }
};

export const DivisionListItem = ({ division }: Props): JSX.Element => {
  /* ******************** Hooks ******************** */
  const dispatch = useDispatch();
  const { selectedDivisions } = useSelector((state: ReduxState) => state.selectedDivisions);
  const { pathname } = useLocation();

  /* ******************** Variables / Functions ******************** */

  const handleAddDivision = (): void => {
    if (!selectedDivisions?.includes(division.id)) {
      dispatch(addSelectedDivisions(division.id));
      dispatch(removeUnassignDivision(division.id));
    } else {
      dispatch(removeSelectedDivisions(division.id));
      dispatch(addUnassignDivision(division.id));
    }
  };

  /* ******************** Renderer ******************** */
  return (
    <List.Item style={{ marginBottom: 4, height: '100%' }}>
      <Card style={styles.wrapper} bodyStyle={styles.container}>
        <Row justify="space-between" align="middle">
          <Col span={18}>
            <Paragraph style={styles.title} ellipsis>
              {division.name}
            </Paragraph>
            <Paragraph style={styles.subTitle} ellipsis={{ rows: 1, tooltip: division.erpId }}>
              {division.erpId}
            </Paragraph>
          </Col>
          <Col span={6}>
            <Row justify="end">
              <Switch
                checked={selectedDivisions.includes(division.id)}
                onClick={handleAddDivision}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                disabled={pathname.includes('overview')}
              />
            </Row>
          </Col>
        </Row>
      </Card>
    </List.Item>
  );
};
