import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  selectedRoles: string[];
  unassignRoles: string[];
  unassignDivisions: string[];
}

const initialState: InitialState = {
  selectedRoles: [],
  unassignRoles: [],
  unassignDivisions: []
};

export const selectedRoles = createSlice({
  name: 'selectedRoles',
  initialState,
  reducers: {
    addRole: (state, { payload }: PayloadAction<string>) => {
      state.selectedRoles = [...state.selectedRoles, payload];
    },
    removeRoles: (state, { payload }: PayloadAction<string>) => {
      state.selectedRoles = state.selectedRoles.filter((item) => item !== payload);
    },
    addUnassignRole: (state, { payload }: PayloadAction<string>) => {
      state.unassignRoles = [...state.unassignRoles, payload];
    },
    removeUnassignRoles: (state, { payload }: PayloadAction<string>) => {
      state.unassignRoles = state.unassignRoles.filter((item) => item !== payload);
    },
    addUnassignDivision: (state, { payload }: PayloadAction<string>) => {
      state.unassignDivisions = [...state.unassignDivisions, payload];
    },
    removeUnassignDivision: (state, { payload }: PayloadAction<string>) => {
      state.unassignDivisions = state.unassignDivisions.filter((item) => item !== payload);
    },
    setRoles: (state, { payload }: PayloadAction<string[]>) => {
      state.selectedRoles = payload;
    },
    resetRoles: (state) => {
      state.selectedRoles = [];
    },
    resetUnassignRoles: (state) => {
      state.unassignRoles = [];
    }
  }
});

export const { addRole, removeRoles, setRoles, resetRoles, addUnassignDivision, removeUnassignDivision, resetUnassignRoles, addUnassignRole, removeUnassignRoles } = selectedRoles.actions;
