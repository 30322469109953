import { Card, Col, List, Row } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { RolesCard } from 'components/atoms/RolesCard';
import React from 'react';
import { useGetRolesQuery } from 'redux/services/cookieMonster/cookieMonsterApi';

export const AssignRolesStep = () => {
  const { data: rolesData, isLoading: isRolesDataLoading, isFetching: isRolesDataFetching } = useGetRolesQuery({ includePermissions: true });

  return (
    <Card>
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <List
            loading={{
              indicator: <BlockLoader direction="loader loader--slideUp" />,
              size: 'large',
              spinning: isRolesDataFetching || isRolesDataLoading
            }}
            dataSource={rolesData?.data}
            renderItem={(item) => <RolesCard roles={item} />}
          />
        </Col>
      </Row>
    </Card>
  );
};
