import { SalesOrderFormMenuKeys } from 'common/enums/MenuKeys';
import { AssignDivisionsStep } from 'components/molecules/AssignDivisionsStep';
import { AssignRolesStep } from 'components/molecules/AssignRolesStep';
import { OverviewStep } from 'components/molecules/OverviewStep';
import UserDetailsStep from 'components/molecules/UserDetailsStep';
import { About } from 'pages/About';
import { CreateUserPage } from 'pages/CreateUser';
import { EditLayout } from 'pages/EditLayout';
import { EditProfilePage } from 'pages/EditProfile';
import { EditUserPage } from 'pages/EditUser';
import { HomePage } from 'pages/Home';
import { RecyclingBinPage } from 'pages/RecyclingBin';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<About />} />
      <Route path="/create" element={<CreateUserPage />}>
        <Route path={SalesOrderFormMenuKeys.UserDetails} element={<UserDetailsStep />} />
        <Route path={SalesOrderFormMenuKeys.AssignRoles} element={<AssignRolesStep />} />
        <Route path={SalesOrderFormMenuKeys.AssignDivisions} element={<AssignDivisionsStep />} />
        <Route path={SalesOrderFormMenuKeys.Overview} element={<OverviewStep />} />
      </Route>
      <Route path="/users/recycling-bin" element={<RecyclingBinPage />} />
      <Route path="/profile/" element={<EditProfilePage />} />
      <Route path="/users/:id" element={<EditLayout />}>
        <Route index element={<EditUserPage />} />
      </Route>
    </Routes>
  );
};
