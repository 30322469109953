import { Col, Form, Row } from 'antd';
import { TextInput } from 'components/UI/FormItems';
import { EmployeeSelectInput } from 'components/UI/FormItems/EmployeeSelectInput';
import { FormRadio } from 'components/UI/FormItems/Radio';
import { useFormikContext } from 'formik';
import { CreateUserPayload } from 'models/Users';
import { useLocation } from 'react-router-dom';
import { useGetEmployeesQuery } from 'redux/services/leftyTheSalesman/leftyTheSalesmanApi';

export const UserForm = (): JSX.Element => {
  // Hooks
  const { values, initialTouched, touched, errors } = useFormikContext();
  const { data: mdsiEmployees, isLoading: isLoadingEmployees } = useGetEmployeesQuery();
  const { pathname } = useLocation();
  const isProfilePage = pathname.includes('/profile');

  const employeeOptions = mdsiEmployees?.data.map((employee) => ({ label: `${employee.name} - ${employee.personnelNumber}`, value: `${employee.name} - ${employee.personnelNumber}` }));

  // Render
  return (
    <Form layout="vertical">
      <Row gutter={[32, 16]}>
        <Col span={24}>
          <TextInput fieldName="givenName" label="First Name" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="familyName" label="Last Name" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="phoneNumber" label="Phone Number" />
        </Col>
        <Col span={24}>
          <TextInput fieldName="email" label="Email" />
        </Col>
        {!isProfilePage && (
          <Col span={24}>
            <FormRadio fieldName="isMdsiEmployee" label="MDSi Employee?" />
          </Col>
        )}
        {(values as CreateUserPayload).isMdsiEmployee && (
          <Col span={24}>
            <EmployeeSelectInput showSearch loader={isLoadingEmployees} options={employeeOptions} fieldName="employeeERPId" label="Employee ERP ID" />
          </Col>
        )}
      </Row>
    </Form>
  );
};
