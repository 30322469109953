import { Col, Row } from 'antd';
import { EmailContainsFilter } from 'components/atoms/Filters/EmailContainsFilter';
import { UserNameContainsFilter } from 'components/atoms/Filters/UserNameContainsFilter';
import RecoverActions from 'components/molecules/RecoverActions';
import { Stack } from 'components/UI';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const HomeControls: React.FC = () => {
  const { pathname } = useLocation();
  const isRecycleBin = pathname.includes('recycling-bin');
  return (
    <>
      <Col>
        {!isRecycleBin ? (
          <Row style={{ marginBottom: 16 }} justify="start">
            <Col span={24}>
              <AntPageTitle text="Search Users" />
            </Col>
          </Row>
        ) : (
          <>
            <Row style={{ padding: 10, background: 'rgb(206, 213, 242)', margin: '0 -4px 8px -4px' }} align="middle" justify="space-between">
              <Col style={{ paddingRight: 16 }}>
                <Row gutter={[8, 8]} justify="center">
                  <Col>
                    <AntPageTitle text="Search Users - Recycle Bin" />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Stack justifyContent="start">
                  <RecoverActions />
                </Stack>
              </Col>
            </Row>
            <Row gutter={[8, 8]} style={{ backgroundColor: 'white', padding: '24px 16px' }}>
              <Col>
                <UserNameContainsFilter />
              </Col>
              <Col>
                <EmailContainsFilter />
              </Col>
            </Row>
          </>
        )}
        {!isRecycleBin && (
          <Row gutter={[8, 8]} style={{ backgroundColor: 'white', padding: '24px 16px' }}>
            <Col>
              <UserNameContainsFilter />
            </Col>
            <Col>
              <EmailContainsFilter />
            </Col>
          </Row>
        )}
      </Col>
      {/* <Row>
        {isRecycleBin && (
          <Row style={{ width: '100%', padding: 10, background: 'rgb(206, 213, 242)' }} align="middle" justify="space-between">
            <Col style={{ paddingRight: 16 }}>
              <Stack justifyContent="start">
                <AntPageTitle text={'Application Recycle Bin'} />
              </Stack>
            </Col>
            <Col>
              <Stack justifyContent="start">
                <RecoverActions />
              </Stack>
            </Col>
          </Row>
        )}
      </Row> */}
    </>
  );
};
