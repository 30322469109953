import { Col, Form, Row, Spin } from 'antd';
import { SwitchInput } from 'components/UI/FormItems';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { useFormikContext } from 'formik';
import { AuthorizedPrinters } from 'models/LegalEntities';
import { useState } from 'react';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';

export const AssignPrinterForm = (): JSX.Element => {
  const { setFieldValue, values } = useFormikContext<AuthorizedPrinters>();
  const { data, isLoading, isFetching } = useGetLegalEntitiesQuery({});
  const [dataAreaId, setDataAreaId] = useState<string>('');

  const printers = data?.data.find((le) => le.dataAreaId === dataAreaId)?.printers;

  return (
    <Form layout="vertical">
      <Row gutter={[8, 8]} style={{ marginTop: 0 }}>
        <Col span={24}>
          <Spin spinning={isLoading || isFetching}>
            <SelectInput
              onChange={(val) => {
                setFieldValue('dataAreaId', val);
                setDataAreaId(val);
              }}
              value={dataAreaId}
              options={data?.data.map((address) => ({ label: address.dataAreaId, value: address.dataAreaId }))}
              fieldName="dataAreaId"
              label="Data Area Id"
            />
          </Spin>
        </Col>
        <Col span={24}>
          <SelectInput
            onChange={(val) => {
              setFieldValue('name', val);
            }}
            disabled={!dataAreaId}
            value={values.name}
            fieldName="name"
            label="Name"
            options={printers?.map((printer) => ({ label: printer.name, value: printer.name }))}
          />
        </Col>
        <Col span={24}>
          <SwitchInput fieldName="isDefault" label="Is Default" />
        </Col>
      </Row>
    </Form>
  );
};
