import { List } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import NoResults from 'components/atoms/noResults/noResults';
import { LoadingMore } from 'components/common/LoadMore';
import { MemberCard } from 'components/molecules/MemberCard';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUsersQuery } from 'redux/services/cookieMonster/cookieMonsterApi';
import { incrementPage } from 'redux/services/cookieMonster/userParams';
import { ReduxState } from 'redux/store';

export const MemberList = (): JSX.Element | null => {
  const { userParams } = useSelector((state: ReduxState) => state);
  const { data, isLoading, isFetching, isError, error } = useGetUsersQuery(userParams, { pollingInterval: 100000 });
  const [loadingMore, setLoadingMore] = useState(false);

  const { nameContains, emailContains } = useSelector((state: ReduxState) => state.userParams);
  const dispatch = useDispatch();

  /* ******************** Render ******************** */
  const hasMore = Boolean(data && data.totalCount > data.data.length);

  const handleLoadMore = (): void => {
    dispatch(incrementPage());
    setLoadingMore(true);
  };

  if (isFetching && userParams.pageNumber === 0) {
    return <BlockLoader direction="loader loader--slideUp" />;
  }

  if (!data?.data.length && !isFetching) {
    return <NoResults errorMessage="No Results Found" />;
  }

  if (nameContains || emailContains) {
    return (
      <InfiniteScroll style={{ overflow: undefined }} loader={<LoadingMore />} hasMore={hasMore} next={handleLoadMore} dataLength={data?.data.length || 0}>
        <List grid={{ gutter: 16, column: 4 }} dataSource={data?.data} renderItem={(item): JSX.Element => <MemberCard user={item} />} />
      </InfiniteScroll>
    );
  } else return <NoResults errorMessage="Please Search for a User" />;
};
