import { Card, Col, List, Row, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { FancyInput } from 'components/atoms/Inputs';
import { Business } from 'models/Business';
import { DivisionsModel } from 'models/DivisionsModel';
import React, { ChangeEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetBusinessesQuery } from 'redux/services/abbyCadabby/abbyCadabbyApi';
import { setCustomerSearch } from 'redux/slices/businessListSlice';
import { ReduxState } from 'redux/store';
import { useDebouncedCallback } from 'use-debounce';
import { BusinessListItem } from './BusinessListItem';

export const AssignDivisionsStep = () => {
  const { data: businessData, isLoading, isFetching } = useGetBusinessesQuery();
  const { customerSearch } = useSelector((state: ReduxState) => state.businessList);

  const dispatch = useDispatch();

  const handleCustomerSearchChange = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setCustomerSearch(event.target.value));
  }, 500);

  const filteredData = useMemo(() => {
    let returnData: Business[] = [];

    // If business and divisions loaded
    if (businessData) {
      for (const business of businessData.data) {
        // Map the divisions
        let fullDivisions: DivisionsModel[] = [];

        for (const division of business.divisions) {
          fullDivisions = [...fullDivisions, ...[division]];
        }

        // Add business filtered for customer search
        returnData = [...returnData, { ...business, divisions: fullDivisions }].map((business) => ({
          ...business,
          divisions: business.divisions.filter((division) => !customerSearch || division.name.toLowerCase().includes(customerSearch) || division.erpId.toLowerCase().includes(customerSearch))
        }));
      }
    }

    // Only return the search values that contain divisions
    const filteredReturnData = customerSearch?.length ? returnData.filter((business) => business.divisions.length) : returnData;

    return filteredReturnData;
  }, [businessData, customerSearch]);

  return (
    <Card>
      <Row gutter={[32, 16]}>
        <Col>
          <Typography.Title style={{ margin: 0 }} level={4}>
            Customers
          </Typography.Title>
        </Col>
        <Col style={{ marginBottom: 16, padding: 0 }}>
          <FancyInput style={{ margin: 0 }} placeholder="Search customers" onChange={handleCustomerSearchChange} />
        </Col>
        <Col span={24}>
          <List
            loading={{
              indicator: <BlockLoader direction="loader loader--slideUp" />,
              size: 'large',
              spinning: isLoading || isFetching
            }}
            dataSource={filteredData}
            renderItem={(item): JSX.Element => <BusinessListItem business={item} />}
            rowKey={(item): string => item.id}
          />
        </Col>
      </Row>
    </Card>
  );
};
